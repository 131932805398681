import {
  Component,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Inject,
  AfterViewInit,
} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {FormControl} from '@angular/forms';
import {filter, finalize, takeUntil} from 'rxjs/operators';
import {LoginService} from '@app/services/login.service';
import {User} from '@app/models/user.model';
import {StorageService} from '@app/services/storage.service';
import {ToastrService} from 'ngx-toastr';
import {UrlService} from '@app/services/url.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {isPlatformBrowser, ViewportScroller} from '@angular/common';
import {WindowScrolling} from '@app/helpers/window-scrolling';
import {ContactsService} from '@app/services/contacts.service';
import {HtmlContentService} from '@app/services/html-content.service';
import {DataService} from '@app/services/data.service';
import {RechargeService} from '@app/services/recharge.service';
import {PriceModel} from '@app/models/price.model';
import {ModalRewardsComponent} from '@app/shared/components/modal-rewards/modal-rewards.component';
import {BonusService} from '@app/services/bonus.service';
import {AppNotification} from '@app/models/stu-notification';
import {AuthenticationService} from '@app/services/authentication.service';
import {v4} from 'uuid';
import {ModalAuthComponent} from './auth-modal/auth-modal.component';
import {BehaviorSubject, interval, Observable, Subject, Subscription} from 'rxjs';
import {environment} from '../../environments/environment';
import {Store} from '@ngrx/store';
import * as cartSelectors from '@app/marketplace/products/state/shopping-cart/cart-selectors';
import * as searchActions from "@app/marketplace/products/state/product-search/search-actions";
import {TrackingService} from '@app/services/tracking.service';
import {FingerprintService} from '@app/services/fingerprint.service';
declare var $zoho: any;
declare let fbq: Function;
declare let gtag: Function;
declare let load3rdPartyScriptsFB: Function;
declare let loadGoogleAnalytics: Function;
declare let load3PartyScriptsZoho: Function;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  providers: [LoginService]
})
export class MenuComponent implements OnInit, AfterViewInit, OnDestroy {

  loadingLoging = false;
  loadingInit = false;
  returnUrl = '/';
  mainPage = false;
  user: User;
  url: string;
  subscription: Subject<any>;
  notifySubscription: Subscription;
  checkboxControl: FormControl;
  checkboxAuthControl: FormControl;
  isPreRechargeMode: boolean;
  price: PriceModel;
  izcr: boolean;

  notificationToView: AppNotification[] = [];

  availableCashback: number;

  isScrollUp = false;
  fixedScroll = 0;

  availableDiscount: number;

  showAvailableDiscount: boolean;
  showAvailableCashBack: boolean;

  landingPage: boolean;
  landingPageLogin: boolean;
  landingPageControl: boolean;
  toRecharge: any;
  amountToRecharge: number;
  promoType: number;

  isRemittancePage = false;
  isMarketPage = false;
  isOldPages = false;

  routerLinkRecarga = '/';
  routerLinkSms = '/sms-a-cuba';
  routerLinkNauta = '/recargas-nauta';
  fragment = 'recargas-a-cuba';

  private _backdrop: HTMLElement | null = null;
  isPromotion = false;
  notificationIndex = 0;
  notificationEnabled = false;
  isLoadingPromoSubject = new BehaviorSubject(true);
  isLoadingPromo = this.isLoadingPromoSubject.asObservable();

  loadAuthentication = false;
  shoppingCart$: Observable<any>;
  did: Observable<string>;

  turnOnShein = environment.turn_on_shein;
  turnOnAmazon = environment.turn_on_amazon;
  turnOnWalmart = environment.turn_on_walmart;
  turnOnHomeDepot = environment.turn_on_homedepot;
  turnOnBestBuy = environment.turn_on_bestbuy;

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    public router: Router,
    private loginService: LoginService,
    private dataService: DataService,
    private rechargeService: RechargeService,
    private contactService: ContactsService,
    private storageService: StorageService,
    private ts: TrackingService,
    private fingerprintService: FingerprintService,
    private toastr: ToastrService,
    private urlService: UrlService,
    private htmlContent: HtmlContentService,
    private rewardsService: BonusService,
    private authService: AuthenticationService,
    public vps: ViewportScroller,
    private store: Store<any>,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.subscription = new Subject();
    this.checkboxControl = new FormControl(false);
    this.checkboxAuthControl = new FormControl(false);
    if (this.isAuthenticated()) {
      this.routerLinkRecarga = '/recargas';
      this.fragment = '';
      this.routerLinkSms = '/sms';
      this.routerLinkNauta = '/nauta';
    }
    this.availableDiscount = 0;
    this.availableCashback = 0;
  }

  getSupportedAnimation() {
    let t, el = document.createElement('stu-animation');

    const animations = {
      'animation': 'animationend',
      'OAnimation': 'oAnimationEnd',
      'MozAnimation': 'animationend',
      'WebkitAnimation': 'webkitAnimationEnd'
    };

    for (t in animations) {
      if (el.style[t] !== undefined) {
        return animations[t];
      }
    }
  }

  notificationSlides() {
    const notifications = Array.from(document.getElementsByClassName('stu-notification-container') as HTMLCollectionOf<HTMLElement>);
    for (let i = 0; i < notifications.length; i++) {
      notifications[i].addEventListener(this.getSupportedAnimation(), function (e) {
        if (e.animationName === 'fadeOut') {
          this.classList.toggle('out');
          this.classList.toggle('active');
        }
      }, {
        passive: true
      });
    }

    if (notifications.length > 0) {
      notifications[this.notificationIndex].classList.remove('active');
      this.notificationIndex === notifications.length - 1 ? this.notificationIndex = 0 : this.notificationIndex++;
      notifications[this.notificationIndex].classList.add('active');
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.isMarketPage = window.location.href.includes('market');
      this.shoppingCart$ = this.store.select(cartSelectors.selectCart);
    }
    if (this.isMarketPage) {
      this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this.returnUrl = event.url;
        });
    }
    this.did = this.fingerprintService.visitorId;
    this.urlService.currentUrl
      .pipe(takeUntil(this.subscription))
      .subscribe(url => {
        if (url) {
          this.url = url;
          this.mainPage = (this.url === '/' || this.url.includes('oferta') ||
            this.url === '' || this.url.includes('/#recargas-a-cuba') ||
            this.url.includes('/recargas-nauta') || this.url.includes('/sms-a-cuba') ||
            this.url.includes('/llamadas-a-cuba') || this.url.includes('/aplicacion') ||
            this.url.includes('/mayorista') || this.url.includes('/market') ||
            this.url.includes('/envio-dinero-cuba') || this.url.includes('/market/vender') ||
            this.url.includes('/?'));
          this.isRemittancePage = this.url.includes('envio-dinero-cuba');
          this.isMarketPage = this.url.includes('market');
          this.isOldPages = !this.url.includes('market');
          if (isPlatformBrowser(this.platformId)) {
            const user: any = JSON.parse(localStorage.getItem('currentUser'));
            if (user) {
              if (user.userLevel == 1 && this.url != '/perfil/recompensas' && this.url != '/perfil/recompensas/descuentos' && this.url != '/perfil/recompensas/reembolso') {
                this.rewardsService.getRewards()
                  .then(result => {
                    this.rewardsService.rewardsSumarySubject.next(result);
                  });
              }
            }
          }
        }
      });

    this.authService.user.subscribe(user => {
      if (user != null) {
        this.onLoginSuccessfull(user);
      }
    });

    this.rewardsService.rewardsSummary
      .subscribe(result => {
        const discount = result.discounts.filter(d => d.offerType == 1 && d.status == 1 && !d.claimed).length || 0;
        if (this.url === '/recargas' || this.url === '/nauta') {
          if (discount == 1) {
            this.toastr.info(`${discount} recompensa disponible`, null, {
              timeOut: 10000,
            });
          } else if (discount > 1) {
            this.toastr.info(`${discount} recompensas disponibles`, null, {
              timeOut: 10000,
            });
          }
        }
        this.availableCashback = result.availableCashback;
        this.availableDiscount = discount;
        this.showAvailableDiscount = true;
        this.showAvailableCashBack = true;
      });
    this.isLoadingPromoSubject.next(true);
    this.htmlContent.contentNotification
      .subscribe((result: any) => {
        if (result) {
          this.isLoadingPromoSubject.next(false);
          this.notificationEnabled = result.notificationEnabled && result.notificationToView.length > 0;
          this.isPreRechargeMode = result.isPreRechargeEnabled || false;
          if (result.isPreRechargeEnabled || result.isPromotionEnabled) {
            this.isPromotion = true;
            this.isPreRechargeMode = result.isPreRechargeEnabled;
          } else {
            this.isPromotion = false;
            this.isPreRechargeMode = false;
          }
          this.notificationToView = result.notificationToView;
          if (isPlatformBrowser(this.platformId)) {
            this.notifySubscription === undefined
              ? this.notifySubscription = interval(8000).subscribe(val => this.notificationSlides())
              : this.notifySubscription.unsubscribe();
          }
        }
      });


    this.checkboxControl.valueChanges
      .subscribe(opended => {
        if (opended) {
          WindowScrolling.disable();
        } else {
          WindowScrolling.enable();
        }
      });

    this.checkboxAuthControl.valueChanges
      .subscribe(opended => {
        if (opended) {
          this.storageService.loadLoginSubject.next(true);
          const modalRef = this.modalService.open(ModalAuthComponent,
            {centered: true, backdrop: 'static', windowClass: 'modal-md modal-auth'});
          modalRef.componentInstance.returnUrl = this.returnUrl;
          modalRef.componentInstance.loadAuthentication = this.loadAuthentication;
          modalRef.result
            .then(result => {
              if (result == true) {
                if (this.router.url.includes('/verificacion-pago/loginLandingPageExistentUser')
                  || this.router.url.includes('/verificacion-pago/landingPage')) {
                  this.checkboxAuthControl.setValue(false);
                }
              }
            });
          if (!this.loadAuthentication) {
            this.loadAuthentication = true;
          }
        } else {
          this.modalService.dismissAll();
          this.authService.setResetForm(true);
          if (this.landingPageControl) {
            this.returnUrl ? this.router.navigate([this.returnUrl]) : this.router.navigate(['']);
          }
        }
      });

    this.storageService.currentUser.subscribe(user => {
      this.user = user;
      if (this.user) {
        this.routerLinkRecarga = '/recargas';
        this.fragment = '';
        this.routerLinkSms = '/sms';
        this.routerLinkNauta = '/nauta';

      } else {
        this.routerLinkRecarga = '/';
        this.fragment = 'recargas-a-cuba';
        this.routerLinkSms = '/sms-a-cuba';
        this.routerLinkNauta = '/recargas-nauta';
      }
    });

    this.storageService.loadExternalLogin$.subscribe(x => this.loadingInit = x);

    this.user = this.storageService.getCurrentUser();
    this.storageService.authorize
      .subscribe((authorized: any) => {
        if (!authorized.authorize) {
          this.returnUrl = authorized.url;
          this.checkboxAuthControl.setValue(true);
        }
      });

    this.route.queryParams
      .subscribe(results => {
        if (this.router.url.includes('/verificacion-pago/loginLandingPageExistentUser')) {
          this.dataService.loadingFromLPSubject.next(true);
          this.storageService.logoutWithoutRedirect();
          this.checkboxAuthControl.setValue(true);
          this.landingPage = true;
          this.landingPageControl = true;
          this.authService.setUsername(results.email);
          this.amountToRecharge = results.amountToRecharge;
          this.promoType = results.promoType;
          this.landingPageTracking(results, 'registered');
          this.toRecharge = {
            accountsToRecharge: [
              {
                account: results.accountToRecharge,
                contactName: '',
                serviceType: results.serviceType == 'CUBACEL' ? 0 : 1,
                guid: v4()
              }],
            landingPage: true,
            sendSms: false,
            message: '',
            sendRechargeNow: results.sendRechargeNow.toLowerCase() == 'false' ? false : true,
            serviceType: results.serviceType == 'CUBACEL' ? 0 : 1,
            utmSource: results.utmSource,
            code: results.code
          };
        } else if (this.router.url.includes('/verificacion-pago/landingPage')) {
          this.storageService.logoutWithoutRedirect();
          this.dataService.loadingFromLPSubject.next(true);
          const uid = results.uid;
          const tid = results.tid;
          this.landingPageTracking(results);
          this.landingPageLogin = true;
          this.landingPageControl = true;
          this.loginService.landingPageLogin(tid, uid)
            .then(result => {
              this.toRecharge = {
                accountsToRecharge: [
                  {
                    account: result.transaction.numberToTopUp,
                    contactName: result.transaction.clientName,
                    guid: v4(),
                    serviceType: result.transaction.operator == 'CUBACEL' ? 0 : 1,
                    priceId: result.price.id,
                  }],
                price: result.price,
                totalDiscount: result.price.discount,
                landingPage: true,
                sendSms: false,
                message: '',
                sendRechargeNow: result.transaction.sendRechargeNow,
                serviceType: result.transaction.operator == 'CUBACEL' ? 0 : 1,
                utmSource: result.transaction.utmSource,
                discount: result.price.discount,
                isExclusive: result.price.isExclusive,
                eligibleCashback: result.price.eligibleCashback,
                maxCashback: result.price.rechargePrice - this.availableCashback < 0 ? this.price.rechargePrice : this.availableCashback,
                transactionId: tid,
                code: results.code
              };
              this.onLoginSuccessfull(result.token);
            }).catch(() => {
            this.returnUrl ? this.router.navigate([this.returnUrl]) : this.router.navigate(['']);
          });
        }
      });
  }
  landingPageTracking(results, user = 'new') {
    if (results.code) {
      this.did.subscribe(visitorID => {
        const tracking = {did: visitorID, at: 4, sid: v4(), ed: {...this.route.snapshot.queryParams, landingPage: user}, dti: visitorID};
        this.ts.notificationDat(tracking).then(() => {
        });
        this.storageService.setPromoCode({code: results.code, expiration: Date.now()});
      }, () => {
        const tracking = {did: '', at: 4, sid: v4(), ed: {...this.route.snapshot.queryParams, landingPage: user}, dti: ''};
        this.ts.notificationDat(tracking).then(() => {
        });
        this.storageService.setPromoCode({code: results.code, expiration: Date.now()});
      });
    }
  }

  ngAfterViewInit(): void {
    this.urlService.recarga_a_cuba
      .subscribe(() => {
        setTimeout(() => {
          const link = document.getElementById('recarga');
          link.click();
        }, 1);
      });
  }

  private onLoginSuccessfull(user: User) {
    this.loadAuthentication = false;
    this.landingPageControl = false;
    this.checkboxAuthControl.setValue(false);
    this.storageService.setCurrentUser(user);
    this.user = user;
    if (this.landingPageLogin) {
      if (isPlatformBrowser(this.platformId)) {
        load3PartyScriptsZoho();
        load3rdPartyScriptsFB();
        loadGoogleAnalytics(environment.production);
        gtag('event', 'stu_login');
        gtag('event', 'login');
        fbq('track', 'stu_login');
        if ($zoho.salesiq.visitor) {
          $zoho.salesiq.visitor.name(user.userFirstName);
          $zoho.salesiq.visitor.email(user.userName);
        }
      }
    } else {
      gtag('event', 'stu_login');
      gtag('event', 'login');
      fbq('track', 'stu_login');
      if ($zoho.salesiq.visitor) {
        $zoho.salesiq.visitor.name(user.userFirstName);
        $zoho.salesiq.visitor.email(user.userName);
      }
    }

    if (this.landingPage) {
      this.dataService.loadingFromLPSubject.next(true);
      this.rechargeService.getPriceDtoByRechargeAmountAndServiceType(this.toRecharge.serviceType, this.amountToRecharge, this.promoType || 0)
        .then(result => {
          this.price = result;
          if (user) {
            const promise = user.userLevel == 1 ? new Promise<void>((resolve, reject) => {
              Promise.all([
                this.contactService.getIZCR().then(res => {
                  this.izcr = res;
                }),
                this.rewardsService.getRewards()
                  .then(reward => {
                    this.availableCashback = reward.availableCashback;
                  })
              ])
                .then(() => {
                    this.toRecharge.accountsToRecharge.map((x: any) => x.priceId = this.price.id);
                    this.landingPage = false;
                    this.updatePriceIntoRechargeModel(result);
                    this.StoreRechargeIntoPromise();
                    resolve();
                  },
                  (reason: any) => {
                    reject(reason);
                  }).catch(() => this.returnUrl ? this.router.navigate([this.returnUrl]) : this.router.navigate(['']));
            }) : new Promise<void>((resolve, reject) => {
              Promise.all([
                this.contactService.getIZCR().then(res => {
                  this.izcr = res;
                }),
              ])
                .then(() => {
                    this.toRecharge.accountsToRecharge.map((x: any) => x.priceId = this.price.id);
                    this.landingPage = false;
                    this.updatePriceIntoRechargeModel(result);
                    this.StoreRechargeIntoPromise();

                    resolve();
                  },
                  (reason: any) => {

                    reject(reason);
                  }).catch(() => this.returnUrl ? this.router.navigate([this.returnUrl]) : this.router.navigate(['']));
            });
          }


        }).catch(() => {
        this.returnUrl ? this.router.navigate([this.returnUrl]) : this.router.navigate(['']);
      });
    } else if (this.landingPageLogin) {
      const promise = new Promise<void>((resolve, reject) => {
        Promise.all([
          this.contactService.getIZCR().then(res => {
            this.izcr = res;
          }),
          this.rewardsService.getRewards()
            .then(result => {
              this.availableCashback = result.availableCashback;
            })
        ])
          .then(() => {
              this.landingPageLogin = false;
              this.StoreRechargeIntoPromise();
              resolve();
            },
            (reason: any) => {

              reject(reason);
            }).catch(() => this.returnUrl ? this.router.navigate([this.returnUrl]) : this.router.navigate(['']));
      });
    } else if ((this.user.promotionMode == 'true' || this.user.promotionMode == true) && !this.returnUrl.includes('revisar-completar-pedido')) {
      this.returnUrl ? this.router.navigate([this.returnUrl]) : this.router.navigate(['/recargas'])
        .then(() => {
          this.loadingLoging = false;
          this.checkboxControl.setValue(false);
          this.toastr.success('Bienvenido, usted ha sido autenticado satisfactoriamente');
        });
    } else {
      this.router.navigate([this.returnUrl])
        .then(() => {
          this.loadingLoging = false;
          this.checkboxControl.setValue(false);
          this.toastr.success('Bienvenido, usted ha sido autenticado satisfactoriamente');
          this.returnUrl = '';
        });
    }
  }

  private StoreRechargeIntoPromise() {
    this.toRecharge.eligibleExtraDiscount = 0;
    this.toRecharge.cashback = 0;
    this.toRecharge.izcr = this.izcr;
    this.toRecharge.availableCashback = this.availableCashback;
    this.toRecharge.isPrerechargeMode = this.isPreRechargeMode;
    this.dataService.changeMessage(this.toRecharge);
    this.dataService.loadingFromLPSubject.next(false);
    this.router.navigate(['/verificacion-pago'])
      .then(() => {
        this.loadingLoging = false;
        this.checkboxControl.setValue(false);
        this.toastr.success('Bienvenido, usted ha sido autenticado satisfactoriamente');
      });
  }

  private updatePriceIntoRechargeModel(price: PriceModel) {
    this.toRecharge.price = price;
    this.toRecharge.eligibleCashback = price.eligibleCashback;
    this.toRecharge.maxCashback = price.rechargePrice - this.availableCashback < 0 ? this.price.rechargePrice : this.availableCashback;
    this.toRecharge.discount = price.discount;
    this.toRecharge.isExclusive = price.isExclusive;
    this.toRecharge.totalDiscount = price.discount;
  }

  public logout(): void {
    if ($zoho) {
      if ($zoho.salesiq) {
        if ($zoho.salesiq.visitor) {
          $zoho.salesiq.visitor.name(undefined);
          $zoho.salesiq.visitor.email(undefined);
        }
      }
    }
    this.loginService.logout();
    this.returnUrl = '/perfil';
  }

  getAuthUserName() {
    return this.user.userFirstName;
  }

  isAuthenticated() {
    return this.storageService.isAuthenticated();
  }

  navigateToHome(path) {
    this.redirectToRecharges1(path);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.notifySubscription !== undefined) {
      this.notifySubscription.unsubscribe();
    }
    this.mainPage = false;
  }

  collapse() {
    this.checkboxControl.setValue(false);
    this.market();
  }
  market = () => this.store.dispatch(searchActions.SetProvider({provider:0}));

  onRewards(cashback: boolean) {
    const modalRef = this.modalService.open(ModalRewardsComponent, {centered: true, windowClass: 'modal-reward'});
    if (cashback) {
      modalRef.componentInstance.selected = 2;
    }
  }

  redirectToRecharges() {
    this.router.navigate(['/'], {state: {data: 'recarga-a-cuba'}});
    this.urlService.fragmentSubject.next('recargas-a-cuba');
  }

  redirectToRecharges1(fragment: string) {
    this.router.navigate(['/'], {state: {data: fragment}});
    this.urlService.fragmentSubject.next(fragment);
  }
}
