// tslint:disable-next-line:max-line-length
import {Component, OnDestroy, OnInit, ViewChild, PLATFORM_ID, Inject, AfterViewInit, Compiler} from '@angular/core';
import {Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import {UrlService} from '@app/services/url.service';
import {takeUntil, filter, first} from 'rxjs/operators';
import {isPlatformBrowser, DOCUMENT} from '@angular/common';
import {environment} from '@env/environment';
import {HtmlContentService} from './services/html-content.service';
import {AppNotificationDisplay} from './models/stu-notification';
import {StorageService} from './services/storage.service';
import {User} from './models/user.model';
import {FingerprintService} from './services/fingerprint.service';
import {Utils} from './helpers/utils';
import {PromotionsService} from './services/promotions.service';
import {Observable, Subject} from 'rxjs';
import {TrackingModel} from './models/tracking/tracking.model';
import {AboutAsComponent} from './about-as/about-as.component';
import {v4 as uuidv4, v4} from 'uuid';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import {TrackingService} from './services/tracking.service';
import {LoginService} from './services/login.service';
import {ModalTermsAndConditionalsComponent} from './shared/components/modal-terms-and-conditionals/modal-terms-and-conditionals.component';
import {TermsAnsConditionsModel} from './models/terms-and-conditions.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TermsAndConditionsService} from './services/terms-and-conditions.service';
import {AuthenticationService} from './services/authentication.service';
import {Title} from '@angular/platform-browser';
import {jwtDecode} from 'jwt-decode';
import {DownloadAppComponent} from '@app/shared/components/download-app/download-app.component';
import {ModalPhoneVerificationComponent} from '@app/shared/components/modal-phone-verification/modal-phone-verification.component';

declare let load3rdPartyScriptsFB: Function;
declare let loadGoogleAnalytics: Function;
declare let load3rdPartyFonts: Function;
declare let load3rdPartyScriptsFirebaseApp: Function;
declare let load3rdPartyScriptsFirebaseAnalitycs: Function;
declare let load3PartyScriptsZoho: Function;
declare let load3PartyScriptsGoHighLevel: Function;
declare let load3PartyScriptsFreshWorks: Function;
declare let loadBootsrtapNative: Function;
declare let loadGoogleClient: Function;
declare let loadDeprecatedGoogleApi: Function;
declare let loadGoogleTagManager: Function;
declare let firebase: any;
declare let fbq: Function;
declare let gtag: Function;
declare let stuvh: Function;
declare let loadFingerPrintPro: Function;
declare var $zoho: any;
declare var GHL: any;
declare let google: any;
const client_id = environment.client_id;
const firebaseConfig = {
  apiKey: 'AIzaSyCzEJ3Ei9xX1jIYodP-iPmhsaj1X_kcqJ0',
  authDomain: 'recarga-cubano.firebaseapp.com',
  databaseURL: 'https://recarga-cubano.firebaseio.com',
  projectId: 'recarga-cubano',
  storageBucket: 'recarga-cubano.appspot.com',
  messagingSenderId: '214805215376',
  appId: '1:214805215376:web:c6f1558ceffa0cf9870015',
  measurementId: environment.production ? 'G-YJKJ98Z78E' : 'G-GN4TF6SFEE'
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {


  @ViewChild('progressBar', {static: true})
  progressBarr: any;

  mainPage: boolean;
  url: string;
  subscription: Subject<any>;
  lazyLoadContent: boolean;
  user: User;
  showfooter = false;
  flashOffer: Observable<boolean>;

  did: string;
  loadingLoging = false;
  isDesktop: boolean;
  ios: string;
  href: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private htmlContent: HtmlContentService,
    private urlService: UrlService,
    private storageService: StorageService,
    private ts: TrackingService,
    private promotionService: PromotionsService,
    private loginService: LoginService,
    private modalService: NgbModal,
    private termsService: TermsAndConditionsService,
    private authService: AuthenticationService,
    private fingerprintService: FingerprintService,
    private title: Title,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.subscription = new Subject();
    this.user = this.storageService.getCurrentUser();
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.isDesktop = this.isDesktop = !(navigator.userAgent.match(/iPad|(iPhone)|(iPod)|(android)|(webOs)/i));
      this.ios = navigator.platform && /iPad|iPhone|iPod/.test(navigator.userAgent) ? 'iOS' : 'Android';
      this.href = this.ios == 'iOS' ? environment.appleStoreUrl : environment.googlePlayUrl;
      this.document.addEventListener('mousemove', this.onLazyLoadContent, {passive: true});
      this.document.addEventListener('click', this.onLazyLoadContent, {passive: true});
      this.document.addEventListener('touchstart', this.onLazyLoadContent, {passive: true});
      //#region FINGERPRINT TRACKING
      const tag = {
        accounts: [],
        transactionType: 0,
        paymentMethod: '',
        invoice: 0,
        email: this.user ? this.user.userName || this.user.email : '',
        save: true
      };
      loadFingerPrintPro(tag).then(result => {
        this.fingerprintService.setVisitorId(result.visitorId);
        this.did = result.visitorId;
        this.onLoadLocalTracking();
      }).catch(err => this.onLoadLocalTracking());
      //#endregion FINGERPRINT TRACKING
      this.urlService.currentUrl
        .pipe(takeUntil(this.subscription))
        .subscribe(url => {
          this.showfooter = false;
          this.url = url;
          this.mainPage = (this.url === '/' || this.url.includes('/?') ||
            this.url.includes('oferta') ||
            this.url === '' ||
            this.url.includes('#recargas-a-cuba') ||
            this.url.includes('/recargas-nauta') ||
            this.url.includes('/sms-a-cuba') ||
            this.url.includes('/llamadas-a-cuba') ||
            this.url.includes('/aplicacion') ||
            this.url.includes('/mayorista') ||
            this.url.includes('/market') ||
            this.url.includes('/envio-dinero-cuba') ||
            this.url.includes('/market/vender'));
        });
    }

    this.flashOffer = this.promotionService.flashOffer$;

    this.urlService.footer.subscribe(() => this.showfooter = true);

    this.storageService.currentUser
      .subscribe(user => this.user = user);

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntil(this.subscription)
      )
      .subscribe(() => {
        this.progressBarr.start();
      }, () => {
        this.progressBarr.complete();
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.progressBarr.complete();
        if (!this.lazyLoadContent) {
          this.onInitRequest();
          this.lazyLoadContent = true;
        }
      }, () => {
        this.progressBarr.complete();
        if (!this.lazyLoadContent) {
          this.onInitRequest();
          this.lazyLoadContent = true;
        }
      });
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (environment.production) {
        stuvh();
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onLazyLoadContent = (event: any) => {
    const self = this;
    //#region 3rd Party Resources
    if (isPlatformBrowser(this.platformId)) {
      loadBootsrtapNative();
      load3rdPartyFonts();
      const promise1 = new Promise((resolve, reject) => {
        Promise.all([
          load3rdPartyScriptsFB(),
          loadGoogleAnalytics(environment.production),
          loadGoogleTagManager(),
        ]).then(() => {
          this.onFBQEvent();
          this.onResetZohoAccount();
        });
      });
      switch (environment.chat) {
        case 'zoho':
          const zoho = new Promise((resolve, reject) => {
            Promise.all([
              load3PartyScriptsZoho()
            ]).then((result) => {
              this.onResetZohoAccount();
            });
          }); break;
        case 'freshWorks':
          const freshWorks = new Promise((resolve, reject) => {
            console.log('test')
            Promise.all([
              load3PartyScriptsFreshWorks()
            ]).then((result) => {
              // (window as any).fcWidget.init
              // Copy the below lines under window.fcWidget.init inside fw_chat_widget function in the above snippet

              // To set user name
              // (window as any).fcWidget.user.setFirstName("John");

              // To set user email
              // (window as any).fcWidget.user.setEmail("john.doe@gmail.com");
            });
          });
      }

      const promise2 = new Promise((resolve, reject) => {
        Promise.all([
          loadGoogleClient()
        ]).then(() => {
          const ads = this.route.snapshot.queryParamMap.keys.find(x => x.startsWith('stu_ads'));
          if (!ads) {
            this.iniGoogleAuth();
          }
        });
      });
      // Initialize an agent at application startup.
      /*const tag = {
        accounts: [],
        transactionType: 0,
        paymentMethod: '',
        invoice: 0,
        email: self.user ? self.user.userName || self.user.email : '',
        save: true
      };
      Utils.loadFingerPrintPro(FingerprintJS, tag).then(result => {
        this.fingerprintService.setVisitorId(result.visitorId);
        this.did = result.visitorId;
        this.onLoadLocalTracking();
      }).catch(err => this.onLoadLocalTracking());*/

      const promise = new Promise((resolve, reject) => {
        Promise.all([
          load3rdPartyScriptsFirebaseApp(),
          load3rdPartyScriptsFirebaseAnalitycs(),
        ]).then(() => {
          firebase.initializeApp(firebaseConfig);
          firebase.analytics();
        });
      });
      this.document.removeEventListener('mousemove', this.onLazyLoadContent);
      this.document.removeEventListener('click', this.onLazyLoadContent);
      this.document.removeEventListener('touchstart', this.onLazyLoadContent);
    }
    //#endregion
  };

  onInitRequest() {
    if (isPlatformBrowser(this.platformId)) {
      this.htmlContent.GetNotifications()
        .then(response => {
          const notification = response as AppNotificationDisplay;
          if (notification !== null && notification !== undefined && notification.success && notification.notifications.length > 0) {
            this.htmlContent.contentNotificationSubject.next({
              isPreRechargeEnabled: notification.isPreRechargeEnabled,
              isPromotionEnabled: notification.isPromotionEnabled,
              notificationEnabled: true,
              notificationToView: notification.notifications.filter(n => n.notificationType == 2),
              notificationToBanner: notification.notifications.filter(n => n.notificationType == 8)
            });
          } else {
            this.htmlContent.contentNotificationSubject.next({
              isPreRechargeEnabled: notification.isPreRechargeEnabled || false,
              isPromotionEnabled: notification.isPromotionEnabled || false,
              notificationEnabled: false,
              notificationToView: [],
              notificationToBanner: null
            });
          }
        }).catch(() => {
        this.htmlContent.contentNotificationSubject.next({
          isPreRechargeEnabled: false,
          isPromotionEnabled: false,
          notificationEnabled: false,
          notificationToView: [],
          notificationToBanner: null
        });
      });
    }
  }

  onFBQEvent() {
    switch (this.url) {
      case '/asistencia':
      case '/contactanos':
        fbq('track', 'Contact');
        break;
      case '/recargas-nauta': {
        gtag('event', 'page_view', {'page_location': environment.host + this.url});
        fbq('track', 'ViewContent', {content_name: 'stu-page-recharge-nauta'});
        break;
      }
      case '/sms-a-cuba': {
        gtag('event', 'page_view', {'page_location': environment.host + this.url});
        fbq('track', 'ViewContent', {content_name: 'stu-page-messages'});
        break;
      }
      case '/llamadas-a-cuba': {
        gtag('event', 'page_view', {'page_location': environment.host + this.url});
        fbq('track', 'ViewContent', {content_name: 'stu-page-call'});
        break;
      }
      case '/aplicacion': {
        gtag('event', 'page_view', {'page_location': environment.host + this.url});
        fbq('track', 'ViewContent', {content_name: 'stu-page-apps'});
        break;
      }
      case '/mayorista': {
        gtag('event', 'page_view', {'page_location': environment.host + this.url});
        fbq('track', 'ViewContent', {content_name: 'stu-page-retail'});
        break;
      }
      case '/terminos': {
        gtag('event', 'page_view', {'page_location': environment.host + this.url});
        fbq('track', 'ViewContent', {content_name: 'stu-page-terms'});
        break;
      }
      case '/market': {
        gtag('event', 'page_view', {'page_location': environment.host + this.url});
        fbq('track', 'ViewContent', {content_name: 'stu-page-market'});
        break;
      }
      case '/market/vente-tus-productos': {
        gtag('event', 'page_view', {'page_location': environment.host + this.url});
        fbq('track', 'ViewContent', {content_name: 'stu-page-market-supplier'});
        break;
      }
      case '/envio-dinero-cuba': {
        gtag('event', 'page_view', {'page_location': environment.host + this.url});
        fbq('track', 'ViewContent', {content_name: 'stu-page-remittance'});
        break;
      }
      case '/terminos-remesas': {
        gtag('event', 'page_view', {'page_location': environment.host + this.url});
        fbq('track', 'ViewContent', {content_name: 'stu-page-remittance-terms'});
        break;
      }
      case '/privacidad': {
        gtag('event', 'page_view', {'page_location': environment.host + this.url});
        fbq('track', 'ViewContent', {content_name: 'stu-page-privacy'});
        break;
      }
      case '/quienes-somos': {
        gtag('event', 'page_view', {'page_location': environment.host + this.url});
        fbq('track', 'ViewContent', {content_name: 'stu-page-about-us'});
        break;
      }
    }
  }

  onResetZohoAccount() {
    const self = this;
    if (environment.chat == 'zoho') {
      $zoho.salesiq.ready = function () {
        if ($zoho.salesiq.visitor && self.user) {
          $zoho.salesiq.visitor.name(self.user.userFirstName);
          $zoho.salesiq.visitor.email(self.user.userName);
        } else {
          $zoho.salesiq.visitor.name(undefined);
          $zoho.salesiq.visitor.email(undefined);
        }
      };
    }
  }

  onLoadLocalTracking() {
    const ads = this.route.snapshot.queryParamMap.keys.find(x => x.startsWith('stu_ads'));
    if (this.route.snapshot.queryParamMap.keys.length > 0 && this.route.snapshot.queryParamMap.keys.find(x => x.startsWith('utm_source'))) {
      const tracking = {did: this.did, at: 4, sid: v4(), ed: this.route.snapshot.queryParams, dti: this.did};
      this.ts.notificationDat(tracking).then(() => {
      });
      if (!this.isDesktop && ads && this.route.snapshot.queryParamMap.get(ads) == '1') {
        const modalRef = this.modalService.open(DownloadAppComponent, {size: 'lg', windowClass: 'download-app', modalDialogClass: 'mx-4 mx-md-auto'});
        modalRef.componentInstance.title = 'Accede a todos los servicios de Cubatel desde su aplicación';
        modalRef.componentInstance.device = this.ios;
      }
    }
  }

  iniGoogleAuth() {
    if (!this.storageService.isAuthenticated()) {
      const self = this;

      function handleCredentialResponse(response) {
        const responsePayload = jwtDecode<any>(response.credential);
        const userData = {
          firstName: responsePayload.given_name,
          lastName: responsePayload.family_name,
          photoUrl: responsePayload.picture
        };
        self.externalAuthProvider(response.credential, responsePayload.email, 'Google', userData);
      }

      google.accounts.id.disableAutoSelect();
      google.accounts.id.initialize({
        client_id: environment.gcId,
        callback: handleCredentialResponse
      });
      google.accounts.id.prompt();
    }
  }

  private externalAuthProvider(token: string, email: string, provider: string, userData: any) {
    this.storageService.loadExternalLoginSubject.next(true);
    this.progressBarr.start();
    const authExternal = {
      ExternalAccessToken: token,
      Provider: provider,
      UserName: email,
      Email: email,
      FirstName: userData.firstName,
      LastName: userData.lastName,
      BirthDay: userData.birthDate,
      client_id: client_id
    };

    this.loginService.externalProviderAuth(authExternal)
      .pipe(first())
      .subscribe(
        user => {

          if (!user.hasLocalAccount) {
            fbq('track', 'CompleteRegistration', {'currency': 'USD', 'value': 0.00});
            gtag('event', 'stu_register');
            fbq('track', 'stu_register');
          }
          const authUser: User = {
            userFirstName: userData.firstName,
            userLastName: userData.lastName,
            userName: user.userName,
            email: user.userEmail,
            access_token: user.access_token,
            refresh_token: user.refresh_token,
            token_type: user.token_type,
            expires: user.expires,
            expires_in: user.expires_in,
            userPhone: '',
            userAvatar: userData.photoUrl,
            termsAndConditionsApproved: user.termsAndConditionsApproved,
            emailNotificationsAllowed: user.emailNotificationsAllowed,
            smsNotificationsAllowed: user.smsNotificationsAllowed,
            provider: provider,
            userLevel: user.userLevel,
            phoneVerified: user.phoneVerified,
            isoCode: user.isoCode,
            isZipCodeRequired: user.isZipCodeRequired,
            promotionMode: user.promotionMode,
            preRechargeMode: user.preRechargeMode
          };
          if (authUser.termsAndConditionsApproved !== true) {
            const modalRef = this.modalService.open(ModalTermsAndConditionalsComponent, {centered: true, backdrop: 'static'}).result
              .then(result1 => {
                if (result1) {
                  const terms = new TermsAnsConditionsModel({termsAndConditionsApproved: true});
                  this.termsService.setTermsAndConditions(terms, authUser.access_token)
                    .then(() => {
                      this.storageService.loadExternalLoginSubject.next(false);
                      this.progressBarr.complete();
                      this.phoneVerification(authUser);
                    })
                    .catch(() => {
                      this.storageService.loadExternalLoginSubject.next(false);
                      this.progressBarr.complete();
                      this.storageService.logout();
                    });
                } else {
                  this.storageService.loadExternalLoginSubject.next(false);
                  this.progressBarr.complete();
                  this.storageService.logout();
                }
              }).catch(() => {
                this.storageService.loadExternalLoginSubject.next(false);
                this.progressBarr.complete();
                this.storageService.logout();
              });
          } else {
            this.storageService.loadExternalLoginSubject.next(false);
            this.progressBarr.complete();
            this.phoneVerification(authUser);
          }
        },
        error => {
          this.storageService.loadExternalLoginSubject.next(false);
          this.progressBarr.complete();
        }
      );
  }

  phoneVerification(data: any) {
    if (data.phoneVerified == 'false') {
      const modalRef = this.modalService.open(ModalPhoneVerificationComponent, {centered: true, windowClass: 'phone-verification', backdrop: 'static', keyboard: false});
      modalRef.componentInstance.access_token = data.access_token;
      modalRef.componentInstance.isoCode = data.isoCode;
      modalRef.result.then(res => {
        if (res.completed) {
          this.loadingLoging = false;
          this.authService.setUser(data);
        } else {
          this.loadingLoging = false;
        }
      }).catch(() => this.loadingLoging = false);
    } else {
      this.loadingLoging = false;
      this.authService.setUser(data);
    }
  }

}
